import userQC from "../apis/userQC";
import { GET_USER_QC } from "./types";
import eventBus from "../common/EventBus";

export const getUserQC = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {},
            };

            const response = await userQC.get(
                '/qc',
                config,
            );

            dispatch({
                type: GET_USER_QC,
                payload: response.data,
            });
        }
        catch (error) {
            console.log(error);
            if (error) {
                eventBus.dispatch("logout");
              }
        }
    }
};