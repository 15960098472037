import { Component } from 'react';

import M from 'materialize-css';

class MainComponent extends Component {
    componentDidMount() {
      M.AutoInit();
    }
}

export default MainComponent;