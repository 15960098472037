import React from 'react';
import { NavLink } from 'react-router-dom';

import './NavBar.css';
import logo from '../../img/listeria.png';

const SignedOutLinks = () => {
  return (
    <div className="navbar-fixed" id="navbar">
      <nav className="#0d47a1 blue darken-4">
        <div className="nav-wrapper">
          <NavLink id="logo-container" to="/" className="brand-logo" style={{ textAlign: 'center', paddingBottom: '50px', maxHeight: "64px" }}>
            <img src={logo} style={{ height: '48px', }} className="responsive-img" height="auto" alt="NZFSSRC Listeria Database" />
            &nbsp;NZFSSRC Listeria Database
          </NavLink>
          <NavLink to="#" data-target="mobile-demo" className="sidenav-trigger"><i className="material-icons">menu</i></NavLink>

          <ul className="right hide-on-med-and-down">
            <li>
              <NavLink to="/login">LOGIN</NavLink>
            </li>
          </ul>
        </div>
      </nav>

      <ul className="sidenav" id="mobile-demo">
        <li><NavLink to="/login">LOGIN</NavLink></li>
      </ul>
    </div>
  )
}

export default SignedOutLinks