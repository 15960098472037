import { GET_ISOLATES,GET_ISOLATES_ERROR } from "../actions/types";

const isolatesReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_ISOLATES:
            return {
                ...state,
                payload: action.payload,
                uploadStatus: action.uploadStatus,
                newColumns: action.newColumns,
            };
        case GET_ISOLATES_ERROR:
            return {
                ...state,
                error : action.error
            };
        default:
            return state;
    }
};

export default isolatesReducer;
