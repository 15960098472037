import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { connect } from "react-redux";

import Login from './components/Login/Login';
import NotFound from './components/NotFound';
import NavBar from './components/NavBar/NavBar';
import HomePage from "./components/HomePage/HomePage";
import Isolates from './components/Isolates/Isolates';
import UserTree from './components/UserTree/UserTree';
import UserQC from './components/QC/UserQC';
import Users from './components/UserAccount/Users';
import UserDetails from './components/UserAccount/UserDetails';
import Help from './components/Help/help';
import quickstart from './components/QuickStart/quickstart';
import AuthVerify from './common/auth-verify';
import { SIGNOUT_SUCCESS } from "./actions/types";
import { signOut } from "./actions/authActions";
import EventBus from "./common/EventBus";
import { MsalContext } from "@azure/msal-react";
import { msalInstance } from './index';

class Routes extends Component{

    static contextType = MsalContext;

    componentDidMount() {
        // const user = AuthService.getCurrentUser();
        // const { auth } = this.props
    
        EventBus.on("logout", () => {
          this.logOut();
          msalInstance.logoutRedirect({ postLogoutRedirectUri: "/"})
        });
    }
    
    componentWillUnmount() {
        EventBus.remove("logout");
    }
    

    logOut  = () => {
        this.props.signOut()
        localStorage.removeItem('user_details')
        localStorage.removeItem('access_token')
        return (dispatch, getState) => {
            dispatch({
                type: SIGNOUT_SUCCESS,
            }) 
        }    
    }

    render() {
        return (
            <BrowserRouter>
                <NavBar />
                <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/home" component={HomePage} />
                    <Route exact path="/isolates" component={Isolates} />
                    <Route exact path="/qc" component={UserQC} />
                    <Route exact path="/usertree" component={UserTree} />
                    <Route exact path="/userdetails" component={UserDetails} />
                    <Route exact path="/admin" component={Users}/>
                    <Route exact path="/help" component={Help}/>
                    <Route exact path="/quickstart" component = {quickstart}/>
                    <Route>
                        <NotFound />
                    </Route>
                    <Route exact path ="/logout"/>
                </Switch> 
                <AuthVerify logOut={this.logOut}/>
            </BrowserRouter>
        );
    } 
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
  }

export default connect(mapStateToProps, {signOut})(Routes);