import React from 'react';

import pdfFile from '../../assets/Quick_Start.pdf';



export const quickstart = () => {

    // onResumeClick = () => {
    //     window.open(pdfFile);
    //   }

    return (
        <main>
            <button style={{padding:'20px'}}>
            <a href = {pdfFile} target = "_blank" rel='noopener noreferrer' className= "waves-effect waves-light btn-small" > Click here to Download Quick Start Pdf</a>
            </button>
            {/* <a onClick={this.onResumeClick}>
            Resume
            </a> */}
        </main>

    )

}
export default quickstart;