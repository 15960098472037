import React from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from "react-redux";

import './NavBar.css';
import logo from '../../img/listeria.png';
import esrlogo from '../../img/ESR.jpg';
import { signOut } from "../../actions/authActions";
import { msalInstance } from '../../index';

const SignedInLinks = (props) => {

  const {auth} = props;
  let userName = auth.email.split('@').shift();
  return (
    <div className="navbar">
      <header>
        <nav className="#0d47a1 blue darken-4">
          <div className="nav-wrapper">
            <div className="row">
              <div className="col s12">
                <NavLink to="#" data-target="sidenav-1" className="left sidenav-trigger hide-on-large-and-up">
                  <i className="material-icons">menu</i>
                </NavLink>
                <NavLink to="/" className="brand-logo">NZFSSRC Listeria Database</NavLink>
              </div>
            </div>
          </div>
        </nav>
      </header>

      <ul id="sidenav-1" className="sidenav sidenav-fixed sidenav-close">
        <li>
          <div className="user-view">
            <NavLink id="logo-container" to="/" className="subheader" style={{ textAlign: 'center', paddingBottom: '50px' }}>
              <img
                src={logo}
                style={{ height: '64px', }}
                className="responsive-img" height="auto" alt="NZFSSRC Listeria Database" />
                  &nbsp;
                  &nbsp;
                <img
                src={esrlogo}
                style={{ height: '48px', }} alt="ESR Logo" />
            </NavLink>
          </div>
        </li>
        <li className="waves-effect waves-light black-text btn-flat">Hello, {userName}</li>
        <li className="divider gray"></li>
        <li><NavLink to="/home" className="waves-effect waves-light black-text btn-flat">Home</NavLink></li>
        <li><NavLink to="/isolates" className="waves-effect-teal waves-light black-text btn-flat">Isolates</NavLink></li>
        <li><NavLink to="/qc" className="waves-effect-teal waves-light black-text btn-flat">QC</NavLink></li>
        <li><NavLink to="/usertree" className="waves-effect-teal waves-light black-text btn-flat">Tree</NavLink></li>
        <li><NavLink to="/userdetails" className="waves-effect-teal waves-light black-text btn-flat">User Details</NavLink></li>
        {
          auth.user_role === "admin" ?
          <li><NavLink to="/admin" className="waves-effect-teal waves-light black-text btn-flat">Admin Access</NavLink></li> :
          null
        }
        <li><NavLink to="/help" className="waves-effect-teal waves-light black-text btn-flat">Help</NavLink></li>
        <li><NavLink to="/quickstart" className="waves-effect-teal waves-light black-text btn-flat">Quick Start</NavLink></li>
        <li className="divider gray"></li>
        {/* <li><NavLink to="/login" className="waves-effect waves-light btn-flat pink-text" onClick={props.signOut}>Logout</NavLink></li> */}
        <li><NavLink to="/login" className="waves-effect waves-light btn-flat pink-text" onClick={() => {props.signOut(); msalInstance.logoutRedirect({ postLogoutRedirectUri: "/"})}}>Logout</NavLink></li>
      </ul>
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
      auth: state.auth
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut())
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignedInLinks);