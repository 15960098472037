import React from 'react';

import MainComponent from '../MainComponent';

import { connect } from 'react-redux';
import { Redirect } from "react-router";

// datatables.net dependencies
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';
import './rowCounts.css';
// import { CSVReader } from "react-papaparse";
import CSVReader from 'react-csv-reader';

// actions
import { getUserIsolates } from '../../actions/isolatesActions';
import { getUserTree } from "../../actions/userTreeActions";
import { refreshToken } from '../../actions/authActions';

// import UploadMetadata from "./UploadMetadata";

// Initializing jQuery and datatables.net
const $ = require('jquery');
$.DataTable = require('datatables.net-dt');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.html5.js');
// require('datatables.net-fixedheader-dt');
require('datatables.net-responsive-dt');
require('datatables.net-select-dt');
const jszip = require('jszip');
window.JSZip = jszip;

const buttonRef = React.createRef();

class Isolates extends MainComponent {
    mounted = false;

    constructor(props) {
        super();
        this.state = {
            isolates: null,
            newColumns: null,
            error:null
        };
    }

    papaparseOptions = {
        header: false,
        dynamicTyping: true,
        skipEmptyLines: true,
        transformHeader: header =>
            header
                .toLowerCase()
                .replace(/\W/g, '_')
    }

    // Call action while component is mounted
    async componentDidMount() {
        this.mounted = true;
        const { auth, uploadStatus, isolates, newColumns } = this.props;
        if (auth.email) {
            if (!uploadStatus) {
                const isolates = await this.props.getUserIsolates();
                this.setState({ isolates: isolates, newColumns: newColumns });
            } else {
                this.setState({ isolates: isolates, newColumns: null });
            }
        }
    }

    // Clearing datatable and destroying the ref element on unmount
    componentWillUnmount() {
        this.mounted = false;
        if (this.$el && this.$el.DataTable()) {
            this.$el.DataTable().destroy(true);
        }
    }

    // Function for loading isolates data into datatable
    updateDataTable(isolates, newColumns) {

        const dataTableColumns = [
            { data: null, },
            { data: 'nzfssnumber', title: 'Id' },
            { data: 'species', title: 'Species' },
            { data: 'sampletype', title: 'Sample Type' },
            { data: 'sourcecountry', title: 'Source Country' },
            { data: 'year', title: 'Year' },
            { data: 'mlstcc', title: 'MLSTcc' },
            { data: 'lineage', title: 'Lineage' },
            { data: 'mlst_7', title: 'MLST' },
            // { data: 'isolateid', title: 'Isolate id' },
            // { data: 'otherisolateid', title: 'Other Isolate Id'},
            // { data: 'laboratory', title: 'Laboratory' }
        ];

        const privateColumns = [
            { data: 'isolateid', title: 'Isolate id' },
            { data: 'otherisolateid', title: 'Other Isolate Id' },
            { data: 'projectclient', title: 'Submitter' },
            // { data: 'laboratory', title:'Laboratory'}
        ]

        privateColumns.forEach(x => {
            if (x['data'] in isolates[0]) {
                dataTableColumns.push(x)
            }
        })


        if (newColumns) {
            newColumns.forEach(x => {
                dataTableColumns.push({ 'data': x, 'title': x });
            });
        }

        this.$el = $(this.el);

        try {
            this.$el.DataTable({
                data: isolates,
                columns: dataTableColumns,
                dom: 'Blfrtip',
                destroy: true,
                scrollY: "50vh",
                scrollCollapse: true,
                paging: true,
                // order: [[ 10, "desc" ]], //default ordering on the projectclient
                buttons: [
                    {
                        text: 'Export to CSV',
                        extend: 'csv',
                        title: 'Isolates',
                        className: 'btn',
                    },
                    {
                        text: 'Export to Excel',
                        extend: 'excel',
                        title: 'Isolates',
                        className: 'btn',
                    },
                    {
                        extend: 'pageLength',
                        className: 'btn',
                    },
                    {
                        text: 'Draw custom tree (select a minimum of 3 rows)',
                        className: 'btn',
                        action: async () => {
                            const data = this.$el.DataTable().rows({ selected: true }).data();
                            const ids = [];
                            if (data.length > 2) {
                                data.toArray().forEach((val, id) => {
                                    ids.push(val['record_id']);
                                });
                                const currIsolates = await this.props.isolates;

                                this.setState({ isolates: currIsolates})

                                this.setState({ userIsolates: ids });

                                this.setState({ userIsolatesLoading: true });

                                // call action with custom isolate IDs as parameter
                                await this.props.getUserTree(this.state.userIsolates, dataTableColumns);

                                // redirect to usertree route after custom action
                                this.props.history.push({ pathname: '/usertree'});
                            }

                            this.setState({ userIsolatesLoading: false });
                        }
                    },
                ],
                ordering: true,
                order: [[11, 'desc']],
                searching: true,
                info: true,
                lengthMenu: [[5, 10, 20, 50, 100], [5, 10, 20, 50, 100, "All"]],
                pageLength: 20,
                displayStart: 20,
                select: {
                    style: 'multi',
                    selector: 'td:not(.control)'
                },
                responsive: {
                    'details': {
                        'type': 'column',
                        'target': 0,
                    },
                },
                columnDefs: [
                    {
                        data: null,
                        defaultContent: '',
                        className: 'control',
                        orderable: false,
                        targets: 0,
                    },
                ],
                bLengthChange: false,
                autoWidth: false,
            });

            this.$el.DataTable().columns.adjust().draw();
        }
        catch (error) {
            console.log(error);
        }
    }

    handleOpenDialog = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.open(e)
        }
    }

    handleOnFileLoad = async (data) => {
        const currIsolates = this.props.isolates;
        await this.props.getUserIsolates(currIsolates, data);
        // Reload page only once
        if (!window.location.hash) {
            window.location.reload();
        }

        // this.$el.draw(true);
        // this.$el.data.reload();
        this.$el = $(this.el);

        // this.$el.cell( 0, 0 ).data(data).draw();
        this.$el.DataTable().header.adjust().draw();
        this.$el.header().adjust();
        this.$el.DataTable().columns.adjust().draw();
    }

    handleOnError = async (err, file, inputElem, reason) => {
        await this.props.getUserIsolates(null, null);
        console.log(err)
    }

    handleOnRemoveFile = async (data) => {
        await this.props.getUserIsolates(null, null);

        // Reload page only once
        if (!window.location.hash) {
            window.location.reload();
        }
    }

    handleRemoveFile = (e) => {
        // Note that the ref is set async, so it might be null at some point
        if (buttonRef.current) {
            buttonRef.current.removeFile(e);
        }
    }

    handleForce = async (data) => {
        const currIsolates = await this.props.isolates;
        await this.props.getUserIsolates(currIsolates, data);
        // Reload page only once
        // if (!window.location.hash) {
        //     window.location.reload();
        // }
        this.$el.draw(true);
        this.$el = $(this.el);
        this.$el.DataTable().columns.adjust().draw();
        this.$el.DataTable().draw(true);
    }

    handleDarkSideForce = async (err) => {
        console.log(err)
    }

    // Load props and render elements
    render() {
        const { auth, isolates, newColumns, error } = this.props;

        if (!auth.email) return <Redirect to='/login' />

        // else if (!auth.refresh_error_code & auth.refresh_error_code === 401) return <Redirect to='/login' />

        if (isolates) {
            this.updateDataTable(isolates, newColumns);
            this.loader = true;
            if(this.el){
                this.loader = false;
            }
            return (
                <main>
                    <div className="row section" style={{
                        minHeight: "20px",
                        padding: "20px",
                        marginBottom: "20px",
                        border: "3px solid #e3e3e3",
                        borderRadius: "8px",
                    }}>
                        <h5>Isolates Data (upload your own metadata using the "BROWSE & UPLOAD TOOL" below)</h5>
                        <div>
                            <>
                                <CSVReader
                                    cssClass="csv-reader-input"
                                    label="Select CSV  "
                                    onFileLoaded={this.handleForce}
                                    onError={this.handleDarkSideForce}
                                    parserOptions={this.papaparseOptions}
                                    inputId="ObiWan"
                                    inputName="ObiWan"
                                    inputStyle={{ color: 'red' }}
                                />
                            </>
                        </div>
                        <hr />

                        {/* Show preloader if custom isolates are selected and loading */}
                        {this.state.userIsolatesLoading ?
                            <div className="container center">
                                <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                                    <div className="indeterminate"></div>
                                </div>
                                <div>
                                    <h4>Loading custom isolates tree, please wait....</h4>
                                </div>
                            </div> :
                            <div></div>
                        }

                        {
                        this.loader ?
                            <div className="container center">
                                <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                                    <div className="indeterminate"></div>
                                </div>
                                <div>
                                    <h4>Loading Isolates, please wait....</h4>
                                </div>
                            </div> : <div></div>
                            // <Loader></Loader> :<div></div>
                        }  
                        {
                        !this.updateDataTable ?
                            <div className="container center">
                                <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                                    <div className="indeterminate"></div>
                                </div>
                                <div>
                                    <h4>Uploading Isolates, please wait....</h4>
                                </div>
                            </div> : <div></div>
                            // <Loader></Loader> :<div></div>
                        } 
                        {/* Show isolates table */}
                        {
                        this.state.userIsolatesLoading  && this.loader  ?  <table></table> :
                        <table className="display nowrap" cellSpacing="0" width="100%" height="80vh" ref={el => this.el = el} /> 
                        }
                        
                        {/* <table className="display nowrap" cellSpacing="0" width="100%" height="80vh" ref={el => this.el = el} />  */}
                        
                    </div>
                </main>
            )
        } else if(error) {
            return (
                <main>
                     <div>
                            <h4>Error Fetching the Data</h4>
                        </div>
                </main>
            )
        } else {
            return (
                <main>
                    <div className="container center">
                        <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                            <div className="indeterminate"></div>
                        </div>
                        <div>
                            <h4>Loading Isolates Data....</h4>
                        </div>
                    </div>
                    {/* <Loader></Loader> */}
                </main>
            )
        }

       
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        isolates: state.isolates.payload,
        uploadStatus: state.isolates.uploadStatus,
        newColumns: state.isolates.newColumns,
        error:state.isolates.error
    }
}

export default connect(
    mapStateToProps,
    { getUserIsolates, getUserTree, refreshToken },
)(Isolates);
