import userTree from '../apis/userTree';
import { GET_USER_TREE } from "./types";

export const getUserTree = (userIsolates, dataTableColumns) => {
    
    
    return async (dispatch, getState) => {
        const token = getState().auth.token;
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
                data: {},
            };

            if (!userIsolates) {
                const response = await userTree.get(
                    '/user_tree',
                    config,
                );

                dispatch({
                    type: GET_USER_TREE,
                    payload: response.data,
                    newColumnData: dataTableColumns
                });
            } else {
                const response = await userTree.post(
                    '/user_tree',
                    {
                        data: {
                            isolates: userIsolates
                        },
                    },
                    config,
                );

                dispatch({
                    type: GET_USER_TREE,
                    payload: response.data,
                    newColumnData: dataTableColumns
                });
            }
        }
        catch (error) {
            console.log(error);
        }
    }
};
