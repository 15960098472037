import React from 'react'

import MainComponent from '../MainComponent';

import { Field, reduxForm } from 'redux-form';
import { connect } from "react-redux";
import { Redirect } from "react-router";

import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";

import { signIn } from "../../actions/authActions";
import './Login.css';

// import esr_logo from './img/ESR_logo.png';
// import massey_logo from './img/Massey_logo.png';
import nzfssrc_logo from './img/NZFSSRC_logo.png';
import LoginMsal from '../LoginMsal/LoginMsal';
import { MsalContext } from "@azure/msal-react";

const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'email',
        'password',
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    };
    return errors;
};

class Login extends MainComponent {
    static contextType = MsalContext;
    constructor() {
        super();
        this.state = {
          show: false
        };
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
      }
    
      showModal = () => {
        this.setState({ show: true });
      };
    
      hideModal = () => {
        this.setState({ show: false });
      };
    componentDidMount() {
        const options = {
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: false,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
        M.AutoInit();
    }

    renderError({ error, touched }) {
        if (touched && error) {
            return (
                <span id="errormessage" data-error="wrong" data-success="right">
                    {error}
                </span>
            );
        }
    }

    onSubmit = (formValues) => {
        this.props.signIn(formValues);
    }

    changePassword = (formValues) => {
        this.props.changePassword(formValues);
    }

    renderEmail = ({ input, label, meta }) => {
        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    }

    renderPassword = ({ input, label, meta }) => {
        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input type="password" {...input} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    }

    render() {
        const { pristine, submitting, authError, auth } = this.props;

        if (auth.email) return <Redirect to='/' />

        return (
            <div className="container">
                <div className="row">
                    <div className="col s12 valign">
                        <div className="card-panel">
                            {/* <div className="card-action #0d47a1 blue darken-4 white-text"> */}
                            {/* <h4 id="title">NZFSSRC Listeria Database</h4> */}
                            {/* <h5 id="title">About</h5> */}
                            {/* </div> */}
                            <div className="card z-depth-0 project-summary">
                                <div className="card-content grey-text text-darken-3">
                                    <h5>Listeria database is developed by ESR.</h5>
                                    <h5>This dashboard contains whole genome sequencing (WGS) data for <em>Listeria monocytogenes</em> for New Zealand isolates. The dashboard allows a user to compare WGS data for a selection of isolates of interest.</h5>
                                    <h5>To access the dashboard, a user must be a member of the <a href="https://www.nzfssrc.org.nz/"><strong>New Zealand Food Safety and Research Centre</strong></a>. Data restrictions may occur according to user access rights to particular isolates.</h5>
                                    <h5>Please <a href="mailto:lucia.rivas@esr.cri.nz"><strong>contact us</strong></a> if you would like to gain access to this database.</h5>
                                    <br />
                                    {/* <h5 className="center"><a href="#login" className="btn btn-large #0d47a1 blue darken-4 modal-trigger">Click here to login</a></h5> */}
                                    <LoginMsal></LoginMsal>
                                    {/* {isAuthenticated? <h5 className="center"><a className="btn btn-large #0d47a1 blue darken-4" onClick={() => msalInstance.loginRedirect(loginRequest)}>Login</a></h5> : null } */}
                                    {/* <div className="center"><a href="#changePassword" className="modal-trigger">Forgot Password?</a></div> */}
                                    {/* <div className="center"><a onClick ={this.showModal} className="modal-trigger">Forgot Password?</a></div> */}
                                    {/* <UpdatePwdModal/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m4">
                        {/* <div className="card">
                        <div className="card-image">
                            <img className="welcome-img" src={esr_logo} alt="ESR logo"/>
                        </div>
                        <div className="card-content card-action">
                            <span className="card-title">ESR</span>
                        </div>
                    </div> */}
                    </div>
                    <div className="col s12 m4">
                        <div className="card">
                            <div className="card-image">
                                <img className="welcome-img" src={nzfssrc_logo} alt="NZFSSRC" />
                            </div>
                            {/* <div className="card-content card-action">
                            <span className="card-title">Food Safety Science and Research Center</span>
                        </div> */}
                        </div>
                    </div>
                    <div className="col s12 m4">
                        {/* <div className="card">
                        <div className="card-image">
                            <img className="welcome-img" src={massey_logo} alt="Massey University"/>
                        </div>
                        <div className="card-content card-action">
                            <span className="card-title">Massey University</span>
                        </div>
                    </div> */}
                    </div>
                </div>

                <div id="login" className="modal card-panel">
                    <h5 className="modal-close">&#10005;</h5>
                    <div className="modal-content center">
                        <div className="card-action #0d47a1 blue darken-4 white-text">
                            <h4 id="title">Enter your credentials</h4>
                        </div>

                        <form
                            onSubmit={this.props.handleSubmit(this.onSubmit)}
                            className="ui form error"
                        >
                            <div className="card-content">
                                <div className="form-field">
                                    <label htmlFor="email">Email address</label>
                                    <Field
                                        id="email"
                                        name="email"
                                        aria-placeholder="Enter email address"
                                        component={this.renderEmail}
                                    />
                                </div>
                                <br />
                                <div className="form-field">
                                    <label htmlFor="password">Password</label>
                                    <Field
                                        id="password"
                                        name="password"
                                        type="password"
                                        placeholder="Enter password"
                                        component={this.renderPassword}
                                    />
                                </div>
                                <br />
                                <div className="form-field center">
                                    <button
                                        type="submit"
                                        disabled={pristine || submitting}
                                        className="btn-large #0d47a1 blue darken-4 waves-effect waves-dark"
                                    >
                                        Login
                                    </button>

                                    <div className="red-text center">
                                        {
                                            authError ?
                                                <p>{authError}</p> : null
                                        }
                                    </div>
                                </div>
                                {/* <div className="form-field center">
                                    <a href="#changePassword">
                                        Forgot Password?
                                    </a>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.auth,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (creds) => dispatch(signIn(creds))
    }
}

const formWrapped = reduxForm({
    form: 'loginForm',
    validate: validate,
})(Login);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(formWrapped);
