import { LOGIN_ERROR, LOGIN_SUCCESS, MSAL_LOGIN_ERROR, MSAL_LOGIN_SUCCESS, REFRESH_ERROR, REFRESH_TOKEN, SIGNOUT_SUCCESS } from '../actions/types';
import { protectedResources } from '../authConfig';
import { msalInstance } from "../index";

const getToken = async () => {
    const account = msalInstance.getActiveAccount();
  
    if (!account) {
        throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
  
    const response = await msalInstance.acquireTokenSilent({
        account: account,
        ...protectedResources.apiTodoList.scopes
    });
  
    return response.accessToken;
  }

const INITIAL_STATE = {
    loading: true,
    isSignedIn: null,
    email: null,
    token: undefined,
    refresh_token: undefined,
    refresh_error: undefined,
    jwt_validity: undefined,
    authError: null,
    user_role: null,
    refresh_error_code: null
};

const authReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOGIN_ERROR:
            return {
                ...state,
                authError: 'Login failed, Check your credentials'
            }
        case LOGIN_SUCCESS:
            return {
                ...state, 
                loading: false, 
                isSignedIn: true, 
                email: action.payload.email, 
                token: action.payload.token,
                user_role: action.payload.user_role,
                refresh_token : action.payload.refresh_token,
                jwt_validity: action.payload.jwt_validity,
                authError: null,
                refresh_error_code: null,
            };
        case SIGNOUT_SUCCESS:
            return {
                ...state, 
                loading: true, 
                isSignedIn: false, 
                email: null, 
                user_role: undefined,
                refresh_token : undefined,
                jwt_validity: undefined,
                token: undefined,
                refresh_error_code: null,
            };
        case REFRESH_TOKEN: 
            return {
                ...state,
                token: action.payload.token
            };
        case REFRESH_ERROR: 
            return {
                ...state,
                refresh_error_code: action.error,
                refresh_error: "Refreshing Token Failed"
            };
        case MSAL_LOGIN_SUCCESS:
            const authTokenMsal = getToken();
            return {
                ...state,
                loading: false, 
                isSignedIn: true, 
                // token: authTokenMsal,
                token: action.payload.accessToken,
                email: action.payload.idTokenClaims.preferred_username,
                name: action.payload.idTokenClaims.name,
                user_role: (action.payload.idTokenClaims.roles.includes("admin") ? 'admin' : 'user' ),
                roles: action.payload.roles
            }
        case MSAL_LOGIN_ERROR:
            return {
                ...state,
                email: null,
                token: null
            }
        default:
            return state;
    }
};

export default authReducer;