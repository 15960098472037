export const SIGN_IN = 'SIGN_IN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';

export const GET_ISOLATES = 'GET_ISOLATES';
export const GET_ISOLATES_ERROR = 'GET_ISOLATES_ERROR';

export const GET_USER_TREE = 'GET_USER_TREE';
export const GET_USER_QC = 'GET_USER_QC';

export const GET_USERS = 'GET_USERS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const GET_USER = 'GET_USER';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';

export const UPDATE_USER_ORG = 'UPDATE_USER_ORG';
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_ERROR = 'REFRESH_ERROR';

export const MSAL_LOGIN_SUCCESS = 'MSAL_LOGIN_SUCCESS';
export const MSAL_LOGIN_ERROR = 'MSAL_LOGIN_ERROR';