import { GET_USER_TREE } from "../actions/types";

const userTreeReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_TREE:
            return {
                ...state,
                payload: action.payload, 
                newColumnData: action.newColumnData
            };
        default:
            return state;
    }
};

export default userTreeReducer;