import React from 'react';
import MainComponent from '../MainComponent';
import { getUserAccount, createUser, deleteUser, updateUserOrg } from "../../actions/usersActions";
import { refreshToken } from "../../actions/authActions";
import { connect } from "react-redux";
import { Field, reduxForm } from 'redux-form';
import CreateModal from "../CreateModal/CreateModal";
import { Redirect } from "react-router-dom";
import M from "materialize-css";
import "materialize-css/dist/css/materialize.min.css";
import {reset} from 'redux-form';

class Users extends MainComponent {
    constructor() {
        super()
        this.state = { users: null, createdUser: null, userEmail : null, userRole: null };
    }

    async componentDidMount() {

        const options = {
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: true,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
        M.AutoInit();

        await this.props.getUserAccount();
    }

    async componentDidUpdate() {
        await this.props.getUserAccount();
    }

    componentWillUnmount() {
        // this.unsubscribe();
    }

    renderHeader = () => {
        // Render the Header for the user Table to show all the details
        let headerElement = ['id', 'email', 'role', 'Organisations','Laboratory', 'Add Orgs/Labs', 'Delete']

        return headerElement.map((key, index) => {
            return <th key={index}>{key.toUpperCase()}</th>
        })
    }

    renderBody = () => {
        // Render the Body for the user Table to show all the details
        const { users } = this.props;
        return users && users.map((user) => {
            // if (user.role !== 'admin') {
            return (
                <tr key={user.id}>
                    <td>{user.id}</td>
                    <td>{user.email}</td>
                    <td>{user.role}</td>
                    <td>{
                        user.organisations ? (
                            Object.values(user.organisations).map(org => {
                                let orgs = []
                                orgs.push(org);
                                return <li key={orgs}>{orgs}</li>
                            })) : null
                    }</td>
                    <td>
                    {
                        user.laboratories ? (
                            Object.values(user.laboratories).map(lab => {
                                let labs = []
                                labs.push(lab);
                                return <li key={labs}>{labs}</li>
                            })) : null
                    }
                    </td>
                    <td>
                        {
                            user.role !== 'admin' ?
                                <a className="waves-effect waves-red btn-small"
                                    onClick={() => this.addOrganisation(user.email, user.role)}>
                                    <i className="material-icons left">add</i>
                                </a> : null
                        }
                    </td>
                    {
                        user.role !== 'admin' ?
                            <td className='operation'>
                                <button className="waves-effect waves-green btn-small red"
                                    onClick={() => this.removeData(user.email)}>
                                    <i className="material-icons left">delete</i>Delete</button>
                            </td> : <td><button className="btn disabled">Delete</button></td>
                    }
                </tr>
            );
        })
    }


  

     onSubmit = async (formValues) => {
        let apiObject = {};
        let orgs = [];
        orgs.push(formValues.organisation)
        apiObject.email = this.state.userEmail
        apiObject.organisations = orgs  
        await this.props.refreshToken();
        await this.props.updateUserOrg(apiObject);
        await this.props.getUserAccount();
        this.props.dispatch(reset('addOrganisationForm'))

        const {updationSuccess} = this.props;

        if(updationSuccess) {
            var Modalelem = document.querySelector('#addOrganisation');
            var instance = M.Modal.init(Modalelem);;
            instance.close();
        }
        
    }

    removeData = async (data) => {
        var deleteObj = { 'email': data };
        await this.props.deleteUser(deleteObj);
        await this.props.getUserAccount();
    }

    addOrganisation = async (email, role) => {
        console.log("data", email, role)
        this.setState({ userEmail : email, userRole: role})
        var Modalelem = document.querySelector('#addOrganisation');
        var instance = M.Modal.init(Modalelem);;
        instance.open();
    }

    renderOrganisation = ({ input, label, meta }) => {
        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;
        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    }

    renderError({ error, touched }) {
        if (touched && error) {
            return (
                <span id="errormessage" data-error="wrong" data-success="right">
                    {error}
                </span>
            );
        }
    }

    handleChange = (event) => {
    }

    renderOrganisationModal = () => {
        const {updationSuccess, updationError} = this.props;
        return (
            <div id="addOrganisation" className="modal card-panel">
                <h5 className="modal-close">&#10005;</h5>
                <div className="modal-content center">
                    <div className="card-action #0d47a1 blue darken-4 white-text">
                        <h4 id="title">Add Organisation/Laboratory for Users</h4>
                    </div>
                </div>
                <form
                    className="ui form error"
                    onSubmit={this.props.handleSubmit(this.onSubmit)}
                    id =  "addOrganisationForm">
                    <div className="card-content">
                        <div className="card-content">
                            <div className="form-field">
                                <label htmlFor="text">Enter Organisation/Laboratory to add</label>
                                <Field
                                    type="text"
                                    name="organisation"
                                    aria-placeholder="Organisation"
                                    required
                                    component={this.renderOrganisation}
                                    value={this.props.fieldValue} />     
                            </div>
                        </div>
                        <br />

                        <div className="form-field center">
                            <button type="submit"
                                className="btn-large #0d47a1 blue darken-4 waves-effect waves-dark">
                                Add
                            </button>
                            <div className="red-text center">
                                        {
                                            updationError ?
                                                <p>{updationError}</p> : null
                                        }
                                    </div>

                                    <div className="green-text center">
                                        {
                                            updationSuccess ?
                                                <p>{updationSuccess}</p> : null
                                        }
                                    </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }



    render() {

        const { auth, users} = this.props;

        if (!auth.email) return <Redirect to='/login' />
        if (users) {
            return (
                <main>
                    <div className="container">
                        <CreateModal/>

                        <table className="responsive-table">
                            <thead>
                                <tr>{this.renderHeader()}</tr>
                            </thead>
                            <tbody>{this.renderBody()}</tbody>
                        </table>
                        <div>{this.renderOrganisationModal()}</div>
                    </div>
                </main>
            )
        } else {
            return (
                <main>
                    <div className="container center">
                        <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                            <div className="indeterminate"></div>
                        </div>
                        <div>
                            <h4>Loading users....</h4>
                        </div>
                    </div>
                </main>
            )
        }
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        updationSuccess: state.userAccount.updationSuccess,
        updationError: state.userAccount.updationError,
        users: state.userAccount.users,
    }
}

const formWrapped = reduxForm({
    form: 'addOrganisationForm'
})(Users);


export default connect(
    mapStateToProps,
    { getUserAccount, createUser, deleteUser, updateUserOrg , refreshToken},
)(formWrapped, Users);