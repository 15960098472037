import userAccount from "../apis/userAccount";
import { GET_USER, UPDATE_PASSWORD} from "./types";
import eventBus from "../common/EventBus";

export const getUser = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {}
        };
        await userAccount.get(
            '/show_user',
            config,
        ).then(response => {
            dispatch({
                type: GET_USER,
                payload: response.data,
            });
        }).catch(error => {
            console.log("ERROR",error)
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};

export const updatePassword = () => {
    return async (dispatch, getState) => {
        const token = getState().auth.token;

        const config = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            data: {}
        };
        await userAccount.patch(
            '/update_password',
            config,
        ).then(response => {
            console.log('Response', response)
            dispatch({
                type: UPDATE_PASSWORD,
            });
        }).catch(error => {
            console.log("ERROR",error)
            if (error) {
                eventBus.dispatch("logout");
              }
        });
    }
};