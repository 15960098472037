import React from 'react';
import MainComponent from '../MainComponent';
import { getUser } from "../../actions/userDetailAction";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import M from "materialize-css";
import {reduxForm } from 'redux-form';
import userAccount from '../../apis/userAccount';
import "materialize-css/dist/css/materialize.min.css";
import ChangePwdModal from '../ChangePwdModal/ChangePwdModal';


const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach(
        // if we have an error string set valid to false
        (val) => val.length > 0 && (valid = false)
    );
    return valid;
}
class UserDetails extends MainComponent {

    constructor(props) {
        super(props);
        this.state = {
            oldPwd: null,
            newPwd: null,
            errors: {
                oldPwd: '',
                newPwd: '',
            },
            updateError: '',
            updateSuccess: ''
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {

        //set the mounted to true when the component is mounted
        this.mounted = true;

        const options = {
            inDuration: 250,
            outDuration: 250,
            opacity: 0.5,
            dismissible: true,
            startingTop: "4%",
            endingTop: "10%"
        };
        M.Modal.init(this.Modal, options);
        M.AutoInit();
        await this.props.getUser();
    }

    componentWillUnmount(){
        this.mounted = false
    }

    handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        let errors = this.state.errors;
        switch (name) {
            case 'oldPwd':
                errors.oldPwd =
                    value.length < 8
                        ? 'Old Passsword should be more than 8 characters long!'
                        : '';
                break;
            case 'newPwd':
                errors.newPwd =
                    value.length < 8
                        ? 'New Password should be more than 8 characters long!'
                        : '';
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value })
    }

    handleSubmit = async (e) => {
        let formValues = {}
        e.preventDefault();
        if (validateForm(this.state.errors)) {
            formValues = {
                "old_password" : this.state.oldPwd,
                "new_password" : this.state.newPwd
            }
            this.updatePassword(formValues)
        } else {
            console.error('Invalid Form')
        }
    }

    updatePassword(formValues) {
        const { auth } = this.props;
        const config = {
            headers: {
                'Authorization': `Bearer ${auth.token}`,
            }
        };
        userAccount.patch(
            '/update_password',
            formValues,
            config
        ).then(res => {
            if(res.status === 200) { 
                this.setState({ 'updateSuccess': res.data.msg , 'updateError': ''});
                var Modalelem = document.querySelector('#changePassword');
                var instance = M.Modal.init(Modalelem);
                instance.close();
            }
        }).catch(error => {
            console.log('error', error);
            if(error){
                this.setState({ 'updateError': error.response.data.msg });
            }
        })
    }

    render() {
        const { user, auth } = this.props;
    
        if (!auth.email) return <Redirect to='/login' />
        if (user) {
            return (
                <main>
                    <div className="container">
                        <div className="row center">
                            <h4>Hi there!!</h4>
                            <ul className="collection with-header">
                                <li className="collection-header"><h4>Your Details</h4></li>
                                <li className="collection-item">EMAIL : {user.email}</li>
                                <li className="collection-item">User Role: {user.access_level}</li>
                                {
                                    user.access_level === 'user' | user.access_level === 'admin' ? 
                                    <ul className="collection-item">Organisations: {user.organisations ? (
                                        Object.values(user.organisations).map(org => {
                                            let orgs = []
                                            orgs.push(org);
                                            return <li key={orgs}>{orgs}</li>
                                        })) : null}</ul> : null
                                }
                                {
                                    user.access_level === 'subuser' ?
                                    <ul className="collection-item">Laboratory: {user.labs ? (
                                        Object.values(user.labs).map(lab => {
                                            let labs = []
                                            labs.push(lab);
                                            return <li key={labs}>{labs}</li>
                                        })) : null}</ul> : null
                                }
                                
                            </ul>
                        </div>
                        {/* <h5 className="center"><a href="#changePassword" className="btn btn-large modal-trigger">Change Password</a></h5> */}
                        {/* <ChangePwdModal></ChangePwdModal> */}
                    </div>
                    {/* <div id="changePassword" className="modal card-panel">
                        <h5 className="modal-close">&#10005;</h5>
                        <div className="modal-content center">
                            <div className="card-action #0d47a1 blue darken-4 white-text">
                                <h4 id="title">Update your credentials</h4>
                            </div>
                        </div>
                        <form
                            // onSubmit={this.props.handleSubmit(this.onSubmit)}
                            className="ui form error"
                            id="updateForm"
                            onSubmit={this.handleSubmit}>
                            <div className="card-content">
                                <div className="form-field">
                                    <label htmlFor="email" className="center">Email address</label>
                                    <h6>{user.email}</h6>
                                </div>
                                <br />
                                <div className="form-field">
                                    <label htmlFor="password">Old password </label>
                                    <input
                                        id="oldPwd"
                                        name="oldPwd"
                                        type="password"
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {errors.oldPwd.length > 0 &&
                                        <div className="red-text center">{errors.oldPwd}</div>}
                                </div>
                                <br />
                                <div className="form-field">
                                    <label htmlFor="password">New password</label>
                                    <input
                                        id="newPwd"
                                        name="newPwd"
                                        type="password"
                                        required
                                        onChange={this.handleChange}
                                    />
                                    {errors.newPwd.length > 0 &&
                                        <div className="red-text center">{errors.newPwd}</div>}
                                </div>
                                <br />
                                <div className="form-field center">
                                    <button
                                        type="submit"
                                        className="btn-large #0d47a1 darken-4 waves-effect waves-dark">
                                        Update
                                    </button>

                                    <div className="red-text center">
                                        {
                                            updateError? 
                                                <p>{updateError}</p> : null
                                        }
                                    </div> 

                                    <div className="green-text center">
                                        {
                                            updateSuccess ?
                                                <p>{updateSuccess}</p> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div> */}
                </main>
            )
        } else {
            return (
                <main>
                    <div className="container center">
                        <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                            <div className="indeterminate"></div>
                        </div>
                        <div>
                            <h4>Loading users....</h4>
                        </div>
                    </div>
                </main>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        user: state.userDetail.payload
    }
}

const formWrapped = reduxForm({
    form: 'updateForm',
})(UserDetails);

export default connect(
    mapStateToProps,
    { getUser },
)(formWrapped, UserDetails);