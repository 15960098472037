import MainComponent from '../MainComponent';
import { Field, reduxForm } from 'redux-form';
import { getUserAccount, createUser, deleteUser } from "../../actions/usersActions";
import { connect } from "react-redux";
import {reset} from 'redux-form';


const validate = (values) => {
    const errors = {};
    const requiredFields = [
        'email'
    ];
    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    });
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    };
    return errors;
};

class CreateModal extends MainComponent {

    constructor() {
        super()
        this.state = { users: null, createdUser: null };
    }


    renderEmail = ({ input, label, meta }) => {
        const className = `field ${meta.error && meta.touched ? 'error' : ''}`;

        return (
            <div className={className}>
                <label>{label}</label>
                <input {...input} autoComplete="off" />
                {this.renderError(meta)}
            </div>
        );
    }

    renderError({ error, touched }) {
        if (touched && error) {
            return (
                <span id="errormessage" data-error="wrong" data-success="right">
                    {error}
                </span>
            );
        }
    }

    onSubmit = async (formValues, dispatch) => {
        formValues.password = 'icecream123';
        await this.props.createUser(formValues);
        await this.props.getUserAccount();
        this.props.dispatch(reset('createForm'))
    }


    render() {

        const { pristine, submitting, creationError, creationSuccess } = this.props;
        return (
            
            <div className="row center">
                Admin can create a user here
                            <div className="card-content grey-text text-darken-3">
                    <h5 className="center">
                        <a href="#createUser" className="btn btn-large #0d47a1 blue darken-4 modal-trigger">Create User</a>
                    </h5>
                </div>
                <div id="createUser" className="modal card-panel">
                    <h5 className="modal-close">&#10005;</h5>
                    <div className="modal-content center">
                        <div className="card-action #0d47a1 blue darken-4 white-text">
                            <h4 id="title">Enter the user details</h4>
                        </div>
                        <form
                            onSubmit={this.props.handleSubmit(this.onSubmit)}
                            className="ui form error"
                        >
                            <div className="card-content">
                                <div className="card-content">
                                    <div className="form-field">
                                        <label htmlFor="email">Email address</label>
                                        <Field
                                            name="email"
                                            aria-placeholder="Enter email address"
                                            component={this.renderEmail}
                                        />
                                    </div>
                                </div>
                                <br />
                                <div>
                                    <label htmlFor ="role">Select User Role</label>
                                    <Field name ="role" component ="select" className="browser-default">
                                    <option value="">Select Role</option>
                                    <option value="admin">Admin</option>
                                    <option value="user" defaultValue>User</option>
                                    <option value="subuser">Sub-User</option>
                                    </Field>
                                </div>

                                <br />
                                <br />
                                {/* <div className="row">
                                            <a className="waves-effect waves-light btn-small">Create</a>
                                        </div>  */}

                                <div className="form-field center">
                                    <button
                                        type="submit"
                                        disabled={pristine && submitting}
                                        className="btn-large #0d47a1 blue darken-4 waves-effect waves-dark"
                                    >
                                        Create User
                                        </button>

                                    <div className="red-text center">
                                        {
                                            creationError ?
                                                <p>{creationError}</p> : null
                                        }
                                    </div>

                                    <div className="green-text center">
                                        {
                                            creationSuccess ?
                                                <p>{creationSuccess}</p> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        creationSuccess: state.userAccount.creationSuccess,
        creationError: state.userAccount.creationError,
        users: state.userAccount.users,
    }
}

const formWrapped = reduxForm({
    form: 'createForm',
    validate: validate,
    initialValues: {},
    enableReinitialize: true, 
})(CreateModal);

export default connect(
    mapStateToProps,
    { getUserAccount, createUser, deleteUser },
)(formWrapped,CreateModal);