import { GET_USER_QC } from "../actions/types";

const userQCReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER_QC:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
};

export default userQCReducer;