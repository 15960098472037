import React from 'react';

function Loader() {
    return (
        <div className="container center">
            <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                <div className="indeterminate"></div>
            </div>
            <div>
                <h4>Loading, please wait....</h4>
            </div>
        </div>
    );
}

export default Loader;