const initState = {    
    homePlaces: [
        {id: 1, title: 'ISOLATES', 'content': 'This table outlines all the Listeria monocytogenes isolates within the database. If a user has isolates in the database, then they will be listed first and be identifiable for that user only. Standard sample information is available to view for all approved users.This table has a search and filter function and a user can make selections of isolates for further analysis and also add user information if they wish (that information is not saved or can be viewed by other users)'},
        {id: 2, title: 'QC (QUALITY CONTROL)', 'content': 'ESR are the curators of the database and ensure that only high-quality genomes and data are included. Quality control (QC) statistics for each isolate’s genome is presented in this table if a user wishes to view it.'},
        {id: 3, title: 'TREE', 'content': 'Phylogenetic trees are used to view the genetic relationship between isolates. Selecting the tree tab will automatically generate a tree for the user’s isolates only. However, it is advised to firstly selected isolates of interest from the isolate tab and then create a custom tree. This will improve visibility. It is recommended to focus analyses by comparing isolates of the same sequence type (ST).'},
        {id: 4, title: 'HELP and Q&As', 'content' : 'Further details about how to use this database and how to interpret phylogenetic trees. Also, common question and answers about whole genome sequencing is included.'},
        {id: 5, title: 'DISCLAIMER', 'content':'The Institute of Environmental Science and Research Limited (ESR) has used reasonable endeavours to ensure the information provided within the New Zealand Food Safety and Science Centre (NZFSSRC) Listeria database and dashboard has been verified and is accurate, but we cannot guarantee that there are no mistakes or errors. ESR does not give any express or implied warranty that the application will be suitable for any purposes other than those specifically agreed to by ESR and NZFSSRC in writing. To the maximum extent permitted by law, ESR accepts no responsibility for any loss or damage that may result from you accessing, or your reliance on, the information provided by the NZFSSRC Listeria database and dashboard.'}
    ]
};

const homeReducer = (state = initState, action) => {
    return state;
}

export default homeReducer;