import React from 'react';

function HomeList({ home }) {
    return (
        <div className="card z-depth-0 project-summary">
            <div className="card-content grey-text text-darken-3">
                <span className="card-title">{ home.title }</span>
                <h5>{ home.content }</h5>
            </div>
        </div>
    )
}

export default HomeList;
