import React, { Component } from 'react';
import { connect } from "react-redux";
import M from 'materialize-css';

import SignedOutLinks from './SignedOutLinks';
import SignedInLinks from './SignedInLinks';

class NavBar extends Component {
  componentDidMount() {
    M.AutoInit();
  }

  render() {
    const { auth } = this.props;

    const links = auth.email ? <SignedInLinks /> : <SignedOutLinks />

    return (
      <div>
        {links}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  // console.log(state.auth);
  return {
    auth: state.auth
  }
}

export default connect(
  mapStateToProps,
)(NavBar);