import React from "react";
import { MsalContext } from "@azure/msal-react";
import { loginRequest } from '../../authConfig';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

class LoginButton extends React.Component {
    static contextType = MsalContext;
    constructor(props) {
        super();

    }

    async componentDidMount() {
    }

    render() {
        const { auth } = this.props;
        const isAuthenticated = this.context.accounts.length > 0;
        const msalInstance = this.context.instance;

        if(auth.token) {
            return <Redirect to='/'></Redirect>
        }
       
        if (!isAuthenticated) {
            return <h5 className="center"><a className="btn btn-large #0d47a1 blue darken-4" onClick={() => msalInstance.loginRedirect(loginRequest)}>Click here to Login</a></h5>
            // return <h5></h5>
            // return <h5 className="center"><a className="btn btn-large #0d47a1 blue darken-4" onClick={() => msalInstance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Logout</a></h5> 
            // return <HomePage/>  
        } else {
            return null
        }
    }
}


const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        msaltoken: state.token
    }
}

export default connect(
    mapStateToProps,
)(LoginButton);

// export default LoginButton;