import { GET_USER } from "../actions/types";

const userDetailReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                payload: action.payload,
            };
        default:
            return state;
    }
};

export default userDetailReducer;