import React from 'react';

import MainComponent from '../MainComponent';

import { connect } from 'react-redux';
import { Redirect } from "react-router";



import HomeList from './HomeList';
import './HomePage.css';


class HomePage extends MainComponent {

    render() {
        const { homes, auth } = this.props;

        if (!auth.email) return <Redirect to='/login' />

        return (
            <main>
                <div className="container">
                    <br />
                    <h4>Some notes about the menu links on the left hand side are below:</h4>
                    <hr />
                    <div>
                        {homes && homes.map(home => {
                            return (
                                <HomeList home={home} key={home.id} />
                            )
                        })}
                    </div>
  
                    <br />
                    <h4>Funding</h4>
                    <hr />
                    <div className="card z-depth-0 project-summary">
                        <div className="card-content grey-text text-darken-3">
                            <h5>We gratefully acknowledge the funding from the New Zealand Food Safety Science and Research Centre and ESR Science Strategic Investment fund.</h5>
                        </div>
                    </div>

                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        homes: state.home.homePlaces,
        auth: state.auth,
    }
}

export default connect(
    mapStateToProps,
)(HomePage);
