import React from 'react';

import MainComponent from '../MainComponent';

import { connect } from 'react-redux';
import { Redirect } from "react-router";

// import MaterialTable from "material-table";
// Import plotly
import Plot from "react-plotly.js";
// datatables.net dependencies
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-buttons-dt/css/buttons.dataTables.css';
import 'datatables.net-responsive-dt/css/responsive.dataTables.css';

import { getUserQC } from "../../actions/qcActions";

// Initializing jQuery and datatables.net
const $ = require('jquery');
$.DataTable = require('datatables.net-dt');
require('datatables.net-buttons-dt');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-fixedheader-dt');
require('datatables.net-responsive-dt');
require('datatables.net-select-dt');
const jszip = require('jszip');
window.JSZip = jszip;


class UserQC extends MainComponent {

    constructor(props) {
        super();
        this.state = { qcTableData: null };
    }

    async componentDidMount() {
        this.mounted = true;
        const { auth } = this.props
        if(auth.email){
            const qc = await this.props.getUserQC();
            this.setState({ qcTableData: qc });
        }
    }

    // Clearing datatable and destroying the ref element on unmount
    componentWillUnmount() {
        this.mounted = false;
        if(this.$el && this.$el.DataTable()){
            this.$el.DataTable().destroy(true);
        }
    }

    // Function for loading isolates data into datatable
    updateDataTable(qcData) {
        this.$el = $(this.el);

        try {
            this.$el.DataTable({
                data: qcData,
                columns: [
                    { data: null, },
                    { data: 'nzfssnumber', title: 'Id' },
                    { data: 'avgquality', title: 'Avg. Quality' },
                    { data: 'avgreadcoverage', title: 'Avg. Read Coverage' },
                    { data: 'length', title: 'Length' },
                    { data: 'n50', title: 'N50' },
                    { data: 'nrbafperfect', title: '# BAF Perfect' },
                    { data: 'nrbafpresent', title: '# BAF Present' },
                    { data: 'nrbasesacgt', title: '# Bases ACGT' },
                    { data: 'nrcontigs', title: '# Contigs' },
                    { data: 'isolateid', title:'Isolate Id'},
                    { data: "otherisolateid", title:'Other Isolateid'}
                ],
                dom: 'Blfrtip',
                destroy: true,
                scrollY: "400px",
                scrollCollapse: true,
                paging: true,
                buttons: [
                    {
                        text: 'Export to CSV',
                        extend: 'csv',
                        title: 'Isolates',
                        className: 'btn',
                    },
                    {
                        text: 'Export to Excel',
                        extend: 'excel',
                        title: 'Isolates',
                        className: 'btn',
                    },
                    {
                        extend: 'pageLength',
                        className: 'btn',
                    },
                ],
                ordering: true,
                order: [[10, 'desc']],
                searching: true,
                info: true,
                lengthMenu: [[5, 10, 20, 50, 100], [5, 10, 20, 50, 100, "All"]],
                pageLength: 10,
                responsive: {
                    'details': {
                        'type': 'column',
                        'target': 0,
                    },
                },
                columnDefs: [
                    {
                        data: null,
                        defaultContent: '',
                        className: 'control',
                        orderable: false,
                        targets: 0,
                    },
                ],
                bLengthChange: false,
            });
        }
        catch (error) {
            console.log(error);
        }
    }

    render() {
        const { auth, userQC } = this.props;

        const avgquality = [], avgreadcoverage = [], _length = [], n50 = [], nrbafperfect = [], nrbafpresent = [], nrbasesacgt = [], nrcontigs = [] , isolateid = [], otherisolateid = [];
        var qcColumns;

        if (!auth.email) return <Redirect to='/login' />

        if (userQC) {
            // Call function to load QC datatable
            this.updateDataTable(userQC);

            // Map userQC data and create independent list items for violin plots
            userQC.map((row, index) => {
                avgquality.push(row.avgquality);
                avgreadcoverage.push(row.avgreadcoverage);
                _length.push(row.length);
                n50.push(row.n50);
                nrbafperfect.push(row.nrbafperfect);
                nrbafpresent.push(row.nrbafpresent);
                nrbasesacgt.push(row.nrbasesacgt);
                nrcontigs.push(row.nrcontigs);
                isolateid.push(row.isolateid);
                otherisolateid.push(row.otherisolateid);
                return null;
            });

            qcColumns = [
                { 'title': "Avg. Quality", 'values': avgquality },
                { 'title': "Avg. Read Coverage", 'values': avgreadcoverage },
                { 'title': "Length", 'values': _length },
                { 'title': "N50", 'values': n50 },
                { 'title': "# BAF Perfect", 'values': nrbafperfect },
                { 'title': "# BAF present", 'values': nrbafpresent },
                { 'title': "# Bases ACGT", 'values': nrbasesacgt },
                { 'title': "# Contigs", 'values': nrcontigs },
                { 'title': "Isolate ID", 'values':isolateid},
                { 'title': "Other Isolate ID", 'values':otherisolateid}
            ];
            this.loader = true
            if(this.el){
                this.loader = false
            }

            return (
                <main>
                    <div className="row">
                        <div className="row section" style={{
                            minHeight: "20px",
                            padding: "20px",
                            marginBottom: "20px",
                            border: "3px solid #e3e3e3",
                            borderRadius: "8px",
                        }}>
                            <h5>QC Data:</h5>
                            <hr />
                            <table className="display nowrap" cellSpacing="0" width="100%" ref={el => this.el = el} />
                        </div>
                    </div>

                    {this.loader ?
                            <div className="container center">
                                <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                                    <div className="indeterminate"></div>
                                </div>
                                <div>
                                    <h4>Loading QC Data, please wait....</h4>
                                </div>
                            </div> :
                            <div></div>
                        } 

                    <div className="row">
                        {qcColumns.map(item => {
                            return (
                                <div className="col s12 m3" key={item['title']}>
                                    <div className="card" style={{ width: "100%" }}>
                                        <Plot
                                            key={item['title']}
                                            data={[
                                                {
                                                    y: item['values'],
                                                    type: 'violin',
                                                    marker: { color: 'red' },
                                                    box: { visible: true },
                                                    line: { color: 'black' },
                                                    meanline: { visible: true },
                                                    x0: item['title'],
                                                    fillcolor: 'lightblue',
                                                },
                                            ]}
                                            layout={{ title: item['title'], autosize: true, }}
                                            useResizeHandler={true}
                                            style={{ width: "100%", height: "100%" }}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </main>
            )
        } else {
            return (
                <main>
                    <div className="container center">
                        <div className="progress" style={{ height: "10px", borderRadius: "10px" }}>
                            <div className="indeterminate"></div>
                        </div>
                        <div>
                            <h4>Loading QC data....</h4>
                        </div>
                    </div>
                </main>
            )
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        userQC: state.userQC.payload,
    }
}

export default connect(
    mapStateToProps,
    { getUserQC },
)(UserQC);
