import { CREATE_USER, GET_USERS, CREATE_USER_ERROR, DELETE_USER, DELETE_USER_ERROR, UPDATE_USER_ORG, UPDATE_USER_ERROR } from "../actions/types";

const INITIAL_STATE = {
    creationError: null, 
    deletionError: null, 
    updationError: null
}

const usersReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                users: action.users,
                creationSuccess: null, 
                creationError: null,
                updationError: null,
                updationSuccess: null
            };
        case CREATE_USER:
            return {
                ...state,
                creationSuccess: 'User Created Successfully',
            }
        case CREATE_USER_ERROR:
            return {
                ...state,
                creationError: 'Error while creating a user'
            }
        case DELETE_USER:
            return { 
                ...state, 
                deletionError: null
            }
           
        case DELETE_USER_ERROR: 
            return { 
                ...state, 
                deletionError: 'User is not deleted'
            }
        case UPDATE_USER_ORG:
            return {
                ...state,
                updationSuccess: 'User Organisations Updated Successfully',
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                updationError: 'Error while Updating User Organisation'
            }
        default:
            return state;
    }
};

export default usersReducer;